.notes-container {
    position: fixed;
    top: 25px;
    right: 0;
    width: 20%;
    height: calc(100% - 50px);
    background-color: #faeec5;
    border-left: 1px solid #ccc;
    padding: 10px;
    boxShadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    z-index: 1000;
    overflow: hidden;
}

.notes-list {
    flex: 1;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: hidden;
}

.maximize-button {
    position: fixed;
    top: 20px;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.minimize-icon {
    cursor: pointer;
    margin-right: 10px;
}

.maximize-icon {
    cursor: pointer;
}

.main-content {
    width: 80%;
    float: left;
    padding: 20px;
}

.main-content.full-width {
    width: 100%;
    float: none;
}

.notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.add-button {
    margin-left: auto;
    margin-right: 3px;
    float: right;
}

.note-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.note-checkbox {
    margin-right: 10px;
}

.note-content {
    flex: 1;
    overflow-wrap: break-word;
    word-break: break-word;
}

.note-title {
    font-size: 1.1rem;
    margin: 0;
    display: inline-block;
    overflow-wrap: break-word;
    word-break: break-word;
}

.note-description {
    font-size: 0.9rem;
    font-style: italic;
    margin: 5px 0;
    overflow-wrap: break-word;
    word-break: break-word;
}

.note-date {
    font-size: 0.9rem;
    color: #666;
    margin: 10px 0;
}

.action-buttons {
    margin-left: auto;
    margin-right: 3px;
}

.note-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
