.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh; /* 100% of the viewport height */
}

.form-container {
    width: 300px; /* Set the desired width for your form */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    boxShadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.label-container {
    text-align: left;
}

.button-container {
    display: flex;
    justify-content: center;
}

.google-signin-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
