.navbar-user {
    position: relative;
    margin-left: 20px;
}

.user-icon {
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    margin-right: 50px;
}

.username {
    position: absolute;
    top: 30px; /* Position the username above the icon */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #333; /* Background color for better visibility */
    color: white;
    font-size: 1rem;
    display: none; /* Hide by default */
    max-width: 200px; /* Adjust the max-width as needed */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis if text overflows */
    z-index: 1000; /* Ensure tooltip appears above other content */
    boxShadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
}

.navbar-user:hover .username {
    display: block; /* Show on hover */
    left: auto;
    right: 30px;
    transform: none;
}
